@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Catamaran", sans-serif !important;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

@media only screen and (max-width: 1250px) {
  .container {
    padding: 0px 15px;
  }
}
